import { graphql, Link } from 'gatsby';
import React from 'react';
import tw, { styled } from 'twin.macro';
import { Layout } from '../components/Layout';
import { SEO } from '../components/SEO';
import { Typography } from '../components/Typography';

const KitIndexWrapper = styled.div`
h2 {
  font-weight: bold;
}

ul {
  li {
    a {
      text-decoration: underline;

      &:hover {
        ${tw`text-gray-600`}
      }
    }
  }
}
`;

const ModelKitsPage = ({ data }) => {
  const kits = data.allWpModelKit.nodes.map((node) => {
    const { title, slug, manufacturer, prodnumber } = node;

    let path = `/model-kits/${slug}`;

    if (!!prodnumber && !!manufacturer) {
      path = `/model-kits/${manufacturer}/${prodnumber}/${slug}`;
    }

    return {
      title: title,
      path: path,
    };
  });
  return (
    <Layout>
      <SEO />
      <KitIndexWrapper>
        <h2>Model Kits</h2>
        <ul>
          {kits.map((kit) => (
            <li>
              <Link to={kit.path}>{kit.title}</Link>
            </li>
          ))}
        </ul>
      </KitIndexWrapper>
    </Layout>
  );
};

export default ModelKitsPage;

export const pageQuery = graphql`
  query ModelKitsPageQuery {
    allWpModelKit {
      nodes {
        title
        slug
        series
        scale
        manufacturer
        prodnumber
      }
    }
  }
`;
